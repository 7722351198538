import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "index", "partial" ]

  connect() {}

  toggle(event){
    this.partialTargets.forEach((element, index) => {
      element.hidden = element.getAttribute('data-interval') !== `interval${this.indexTarget.value}`
      element.querySelector(".sisters-index").classList.add("wobble-shorter");
    });
  }
}
