import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'charterAttachedSelect',
    'charterExpiryDateInput',
    'charterRateInput',
    'calculateButton',
    'form']

  connect() {
    this.initDatepicker()
    this.validateCharterAttached()
    this.attachInputListeners()
  }

  initDatepicker() {
    if (!this.hasCharterExpiryDateInputTarget) return;

    $(this.charterExpiryDateInputTarget).datepicker({
      dateFormat: 'dd-mm-yy',
      updateViewDate: true,
      changeMonth: true,
      changeYear: true,
      defaultDate: new Date(),
      onSelect: () =>
          this.charterExpiryDateInputTarget.dispatchEvent(new Event('change', { bubbles: true }))
    });
  }

  // Attach event listeners for all input and select elements in the form
  attachInputListeners() {
    this.formTarget.querySelectorAll("input, select").forEach((element) => {
      element.addEventListener('change', () => this.validateCharterAttached())
      element.addEventListener('input', () => this.validateCharterAttached())
    })
  }

  validateCharterAttached() {
    const dateFieldIsEmpty = this.charterExpiryDateInputTarget.value.trim() === ''
    const rateFieldIsEmpty = this.charterRateInputTarget.value.trim() === ''
    const isCharterAttached = this.charterAttachedSelectTarget.value === 'true'

    this.charterExpiryDateInputTarget.classList.remove('field-error')
    this.charterExpiryDateInputTarget.disabled = false
    this.charterRateInputTarget.classList.remove('field-error')
    this.charterRateInputTarget.disabled = false
    this.enableSubmit()

    if (isCharterAttached && (dateFieldIsEmpty || rateFieldIsEmpty)) {
      if(dateFieldIsEmpty) {
        this.charterExpiryDateInputTarget.classList.add('field-error')
      }
      if(rateFieldIsEmpty) {
        this.charterRateInputTarget.classList.add('field-error')
      }
      this.disableSubmit()
    }
    if (!isCharterAttached) {
      this.charterExpiryDateInputTarget.disabled = true
      this.charterRateInputTarget.disabled = true
    }
  }

  enableSubmit() {
    this.calculateButtonTarget.disabled = false
  }

  disableSubmit() {
    this.calculateButtonTarget.disabled = true
  }
}
